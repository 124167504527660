<!--
 * 购买时长卡
-->
<template>
  <div class="container recharge-page">
    <van-nav-bar
      title="购买"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      right-text="购买记录"
      fixed
    />
    <van-tabs v-model="active">
      <van-tab title="购买时长">
        <div class="buy-time-card-container">
          <div class="time-remaining-container">
            <div class="title">剩余时长</div>
            <div class="time">
              剩余时间：<span>{{ hours }}</span
              >小时 : <span>{{ minutes }}</span
              >分钟
            </div>
          </div>
          <div class="goods-list-container">
            <div class="card-list-wrap">
              <div class="card-list-container">
                <div
                  v-for="item of list"
                  :key="item.goodsId"
                  class="card-info"
                  :class="
                    selectedGoods.goodsId === item.goodsId ? 'active' : ''
                  "
                  @click="selectedGoods = item"
                >
                  <div class="card-name">{{ item.goodsName }}</div>
                  <div class="salePrice">
                    <span>¥</span>{{ item.salePrice }}
                  </div>
                  <div class="originalPrice">¥{{ item.originalPrice }}</div>
                </div>
              </div>
            </div>

            <div class="card-detail-container" v-show="selectedGoods.goodsId">
              <div class="title">商品详情</div>
              <div class="content">
                可以使用云电脑{{
                  Math.round(selectedGoods.useableSeconds / 3600)
                }}小时，有效{{ selectedGoods.effectiveDay }}天
              </div>
            </div>
          </div>
          <div class="payment-list-container">
            <div class="pay-container">
              <div class="name">
                <van-image
                  width="18"
                  height="18"
                  :src="require('@/assets/icon/wxpay-icon.png')"
                />
                <span class="pay-name">微信</span>
              </div>
              <van-checkbox :value="true"></van-checkbox>
            </div>
          </div>
        </div>
        <div class="submit-order-container">
          <div class="btn">
            <van-button
              type="primary"
              color="#CB7E0E"
              block
              round
              @click="onPay"
              :disabled="!selectedGoods.goodsId"
              >购买</van-button
            >
          </div>
          <div class="agreement">
            点击支付即代表阅读和同意<span
              @click="$router.push({ name: 'rechargeAgreement' })"
              >《用户充值协议》</span
            >
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getTimeGoodsList, mpPurchase } from '@/api/goods'
import { userInfo } from '@/api/user'
import convert from 'convert-seconds-to-human'
export default {
  name: 'recharge',
  data() {
    return {
      selectedGoods: {},
      list: [],
      usableSecond: 0
    }
  },
  computed: {
    hours() {
      let timeObj = convert(this.usableSecond)
      let hours =
        (timeObj.years || 0) * 24 * 365 +
        (timeObj.days || 0) * 24 +
        (timeObj.hours || 0)
      return hours || 0
    },
    minutes() {
      return convert(this.usableSecond).minutes || 0
    }
  },
  created() {
    this.getUserInfo()
    getTimeGoodsList().then((res) => {
      if (res.status === 200) {
        this.list = res.data
        if (this.list.length > 0) {
          this.selectedGoods = this.list[0]
        }
      }
    })
  },
  mounted() {},
  methods: {
    onPay() {
      this.$toast.loading({
        message: '支付中',
        forbidClick: true
      })
      console.log(this.goodsId)
      let params = {
        goodsId: this.selectedGoods.goodsId
      }
      mpPurchase(params).then((res) => {
        if (res.status === 200) {
          this.callPay(res.data.payData)
        }
      })
    },
    getUserInfo() {
      userInfo().then((res) => {
        if (res.status === 200) {
          this.usableSecond = res.data.usableSecond
        }
      })
    },
    callPay(data) {
      let that = this
      // 需要先判断是否是微信浏览器，并且微信内置包是否加载完成
      // 有时会出现当触发事件的时候微信内置包还未加载完成，必须加上下面判断
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            that.onBridgeReady(data),
            false
          )
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(data))
          document.attachEvent(
            'onWeixinJSBridgeReady',
            that.onBridgeReady(data)
          )
        }
      } else {
        // 执行下面方法
        // 传入下面参数,这些参数需要从后台获取
        // onBridgeReady(appId,timeStamp,nonceStr,package,paySign);
        that.onBridgeReady(data)
      }
    },
    onBridgeReady(data) {
      // eslint-disable-next-line
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, // 随机串
          package: data.package,
          signType: 'MD5', // 微信签名方式：
          paySign: data.paySign, // 微信签名
          jsApiList: [
            'checkJsApi',
            'startRecord',
            'stopRecord',
            'translateVoice',
            'scanQRCode',
            'openCard'
          ]
        },
        function (res) {
          // alert(JSON.stringify(res))
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            this.$toast.clear()
            this.$router.replace({ name: 'recharge' })
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            this.$toast('支付失败')
            // that.$router.replace({
            //   name: 'order-list'
            // })
          }
        }
      )
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      this.$router.push({ name: 'order' })
    }
  }
}
</script>

<style lang="less" scoped>
.recharge-page {
  padding-top: 44px;
  overflow: auto;
  /deep/.van-tab {
    flex-grow: 0;
    flex-basis: 94px;
  }
  .buy-time-card-container {
    padding: 16px 0;
    .time-remaining-container {
      margin: 0 12px;
      margin-bottom: 18px;
      padding: 24px 14px 0 14px;
      height: 114px;
      background-image: url('../../assets/images/time-remaining-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: @text-color-1;
      .title {
        margin-bottom: 26px;
        font-size: 18px;
        font-weight: bold;
      }
      .time {
        font-size: 13px;
        > span {
          display: inline-block;
          min-width: 26px;
          height: 26px;
          padding: 1px 5px 2px;
          background: @orange;
          text-align: center;
          line-height: 26px;
          border-radius: 5px;
          margin: 0 5px;
        }
      }
    }
    .goods-list-container {
      margin-bottom: 12px;
      overflow: auto;
      .card-list-wrap {
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .card-list-container {
          padding: 0 16px;
          margin-bottom: 16px;
          display: flex;
          width: max-content;

          // justify-content: space-between;
          .card-info {
            flex: none;
            padding: 0 4px;
            width: 105px;
            height: 130px;
            border: 1px #626262 solid;
            border-radius: 4px;
            color: @text-color-1;
            background: #323232;
            &:not(:last-child) {
              margin-right: 8px;
            }
            .card-name {
              margin-bottom: 12px;
              height: 43px;
              line-height: 43px;
              font-size: 18px;
              text-align: center;
              border-bottom: 1px dashed #666;
            }
            .salePrice {
              margin-bottom: 16px;
              font-size: 21px;
              text-align: center;
              > span {
                margin-right: 3px;
                font-size: 11px;
              }
            }
            .originalPrice {
              text-decoration: line-through;
              color: #777;
              font-size: 13px;
              text-align: center;
            }
            &.active {
              background: #262114;
              border-color: @orange;
              color: @orange;
              .salePrice {
                color: @orange;
              }
              .card-name {
                border-bottom: 1px dashed @orange;
              }
            }
          }
        }
      }
      .card-detail-container {
        margin: 0 16px;
        padding-bottom: 20px;
        border-bottom: 1px solid #333;
        font-size: 12px;
        .title {
          margin-bottom: 8px;
          color: @text-color-1;
        }
        .content {
          color: @text-color-2;
        }
      }
    }
    .payment-list-container {
      margin: 0 12px;
      .pay-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        .name {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: @text-color-1;
          .pay-name {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .submit-order-container {
    margin: 30px 31px;
    text-align: center;
    .btn {
      margin-bottom: 10px;
    }
    .agreement {
      font-size: 12px;
      color: @text-color-2;
      > span {
        color: @orange;
      }
    }
  }
}
</style>
